// import Turbolinks from 'turbolinks'
import Swiper from 'swiper'
// Turbolinks.start()

window.addEventListener('load', () => {
    const homeSlider = new Swiper('.home .frontpage-slider', {
        autoplay: true,
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    })

    const productSliders = new Swiper('.product-slider .swiper-container', {
        autoplay: false,
        slidesPerView: 1,
        spaceBetween: 16,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            600: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 4,
            },
            1024: {
                slidesPerView: 5,
            },
        },
    })

    const brandsSlider = new Swiper('.brands .swiper-container', {
        autoplay: true,
        slidesPerView: 1,
        spaceBetween: 16,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            600: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 4,
            },
            1024: {
                slidesPerView: 6,
            },
        },
    })

    /**
     * Toggle mobile nav menu
     */
    const mobileMenu = document.querySelector('.mobile-menu')

    mobileMenu.addEventListener('click', function () {
        document.querySelector('.nav-primary ul').classList.toggle('active')
        document.body.classList.toggle('no-scroll')
    })

    const subMenus = document.querySelectorAll('.menu-item-has-children')

    subMenus.forEach((menu) => {
        menu.addEventListener('click', (el) => {
            console.log('click')
            el.target.classList.toggle('active')
        })
    })

    const searchToggle = document.querySelector('.search-toggle')
    const searchNav = document.querySelector('.nav-search')

    searchToggle.addEventListener('click', function (e) {
        e.preventDefault()
        searchNav.classList.toggle('active')
    })

    /**
     * Toggle filtre WooCommerce
     */
    if (
        document.querySelector('.woocommerce-sidebar') &&
        document.querySelector('.woocommerce-sidebar-toggle')
    ) {
        const sidebarToggle = document.querySelector(
            '.woocommerce-sidebar-toggle',
        )
        const sidebar = document.querySelector('.woocommerce-sidebar')

        const closeSidebar = document.querySelector('.close-sidebar')

        sidebarToggle.addEventListener('click', function () {
            sidebar.classList.toggle('active')
            document.body.classList.toggle('no-scroll')
        })

        closeSidebar.addEventListener('click', function () {
            sidebar.classList.toggle('active')
            document.body.classList.toggle('no-scroll')
        })
    }

    if (document.querySelectorAll('.tabbed-content')) {
        document.querySelectorAll('.tabbed-content').forEach((section) => {
            let buttons = section.querySelectorAll('button')

            buttons.forEach((button) => {
                button.addEventListener('click', function () {
                    let currentButton = section.querySelector('button.active')
                    let currentTab = section.querySelector('.tab.active')
                    let targetTab = section.querySelector(
                        '#' + button.dataset.toggle,
                    )

                    currentButton.classList.remove('active')
                    button.classList.add('active')

                    currentTab.classList.remove('active')
                    targetTab.classList.add('active')
                })
            })
        })
    }
})
